import React from 'react';
import SEO from '../components/seo';
import LocalizedLink from '../components/LocalizedLink'
import * as S from "../components/Icon/styled"
import "../components/CSS/rotate.css"

const Success = () => {
  return (
    <>
      <div className="homepage">
        <SEO title="Teşekkürler | Retmon" />

        <div
          style={{
            height: '100vh',
            textAlign: '-webkit-center',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }} >
          <div 
           style={{
            marginBottom: '20rem',
          }}
          >
            <S.Rotate className='rotating'>
              <S.RetmonRotateIcon />
            </S.Rotate>
            <h1>Formunuz tarafımıza ulaştı. En kısa sürede sizinle iletişime geçeceğiz.</h1>
            <br></br>
            <LocalizedLink to='/'> Ana Sayfaya Dön</LocalizedLink>
          </div>
        </div>
      </div>
    </>
  )
}
export default Success
